<template>
  <div>
    <!-- <div class="searchBox">
      <div class="searchLeft">
        <div>
          <el-input
              size="medium"
              clearable
              v-model="searchForm.name"
              placeholder="请输入banner名称"
              @keyup.enter.native="getTableList"
          ></el-input>
        </div>
        <el-button size="medium" type="primary" icon="el-icon-search" @click="getTableList(true)">搜索</el-button>
      </div>
      <div class="searchRight">
        <el-button
          size="medium"
          type="warning"
          icon="el-icon-plus"
          @click="addFunc"
          >新增</el-button
        >
      </div>
    </div> -->

    <el-table border :data="tableData" height="76vh" style="width: 99%">
      <el-table-column prop="id" label="id" width="100px"></el-table-column>
      <el-table-column prop="type" label="类型"></el-table-column>
      <el-table-column prop="appVer" label="version"></el-table-column>
      <el-table-column prop="platform" label="platform"></el-table-column>
      <el-table-column prop="country" label="country">
        <template slot-scope="scope">
          <span>{{countryName(scope.row.country)}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="lang" label="lang"></el-table-column>
      <el-table-column label="config" min-width="500px">
        <template slot-scope="scope">
          <span>{{scope.row.config}}</span>
        </template>
      </el-table-column>
      <el-table-column label="状态" width="100px">
        <template slot-scope="scope">
          <span>{{scope.row.status===1?'上架':'下架'}}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="210">
        <template slot-scope="scope">
          <el-button size="small" type="primary" @click="toEdit(scope.row)"
            >编辑</el-button
          >
          <el-button
            size="small"
            type="warning" 
            @click="unmountFunc(scope.row)"
            >{{scope.row.status===1?'下架':'上架'}}</el-button
          >
          <el-button
            size="small"
            type="danger" 
            @click="delFunc(scope.row)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <pagination :totalNum="totalNum" :currentPage.sync="searchForm.pageNo" :pageSize.sync="searchForm.pageSize" @paginationChange="getTableList"></pagination>
    <el-dialog  :close-on-click-modal="false" :title="titleText" :visible.sync="addVisible" width="800px">
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="300px"
        class="demo-ruleForm"
      >
      <template v-if="titleText=='审核开关'">
          <el-form-item label="审核开关" prop="config.openAudit" :rules="rules.changeRule">
            <el-switch
              v-model="ruleForm.config.openAudit"
              active-color="#13ce66"
              inactive-color="#dcdfe6"
              active-text="开"
              inactive-text="关">
            </el-switch>
          </el-form-item>
        </template>
        <template v-else>
          <el-form-item :label="key" v-for="(value, key) in ruleForm.config" :key="key">
              <el-switch
                v-if="typeof ruleForm.config[key] === 'boolean'"
                v-model="ruleForm.config[key]"
                active-color="#13ce66"
                inactive-color="#dcdfe6"
                active-text="开"
                inactive-text="关">
              </el-switch>
            <el-input
              v-else
              placeholder="请输入内容"
              v-model="ruleForm.config[key]"
            ></el-input>
          </el-form-item>
        </template>
        <el-form-item label="平台">
          <el-radio-group v-model="ruleForm.platform">
            <el-radio label="ios">ios</el-radio>
            <el-radio label="android">android</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="版本号">
          <el-input
            placeholder="请输入内容"
            v-model="ruleForm.appVer"
          ></el-input>
        </el-form-item>
        <el-form-item label="注册国家" prop="country">
            <el-select v-model="ruleForm.country" filterable placeholder="请选择" @change="changeCountryCode">
              <el-option
                v-for="item in countryOptions"
                :key="item.id"
                :label="`${item.countryChineseName} (${item.abv})`"
                :value="item.abv">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="国家语言">
            <el-select v-model="ruleForm.country" placeholder="请选择" disabled>
              <el-option
                v-for="item in countryOptions"
                :key="item.id"
                :label="`${item.languageIso639Code} (${item.countryChineseName})`"
                :value="item.abv">
              </el-option>
            </el-select>
          </el-form-item>
      </el-form>
      
      
      <span slot="footer" class="dialog-footer">
        <el-button @click="addVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitFunc">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import uploadImg from '@/components/uploadImg'
import pagination from '@/components/pagination'
export default {
  data() {
    return {
      totalNum: 0,
      addVisible: false,
      ruleForm: {},
      searchForm: {
        pageNo: 1,
        pageSize: 10
      },
      tableData: [],
      rules: {
        blurRule: [
          { required: true, message: "请输入", trigger: "blur" },
        ],
        changeRule: [
          { required: true, message: "请选择", trigger: "change" },
        ],
        typeIcon: [{ required: true, message: "请上传图片", trigger: "change" }],
      },
      titleText: '',
      countryOptions:[]
    };
  },
  components:{uploadImg,pagination},
  created() {
    this.getTableList();
    this.getCountryList();
  },
  methods: {
    countryName(countryCode){
      return this.countryOptions.filter(item => item.abv === countryCode)[0]?.countryChineseName
    },
    changeCountryCode(val){
      this.ruleForm.lang = this.countryOptions.find(item => item.abv === val).languageIso639Code
    },  
    async getCountryList() {
      let res = await this.$http.countryGroupConfigCodeList({pageNo:1,pageSize:1000});
      if (res) {
        this.countryOptions = res.result.data;
      }
    },
    title(row) {
      // OPEN_AUDIT --审核开关  CALL_COST_CONFIG --通话扣费配置  TX_CONFIG -- 腾讯配置  WALLET_CONFIG -- 钱包配置  COMMON_CONFIG -- 通用配置
      if(row.type=='OPEN_AUDIT') { 
        return '审核开关'
      } else if(row.type=='CALL_COST_CONFIG') {
        return '通话扣费配置'
      } else if(row.type=='TX_CONFIG') {
        return '腾讯配置'
      } else if(row.type=='WALLET_CONFIG') {
        return '钱包配置'
      } else if(row.type=='COMMON_CONFIG') {
        return '通用配置'
      }
    },
    addFunc(){
      this.ruleForm = {}
      this.addVisible = true;
    },
    toEdit(data) {
      this.titleText = this.title(data)
      this.ruleForm = {...data,config:{...JSON.parse(data.config)}};
      this.addVisible = true;
    },
    async submitFunc() {
      this.$refs.ruleForm.validate(async (valid) => {
        if (valid) {
          let ruleForm = {...this.ruleForm}
          ruleForm.config = JSON.stringify(ruleForm.config)
          let res = await this.$http.appConfigSave({...ruleForm});
          if (res) {
            this.$message.success("操作成功！");
            this.getTableList();
            this.addVisible = false;
            this.$refs.ruleForm.resetFields();
          }
        }
      });
    },
    async getTableList(val) {
      if(val) {
        this.searchForm.pageNo = 1
      }
      let res = await this.$http.appConfigList({...this.searchForm});
      if (res) {
        this.tableData = res.result.data;
        this.totalNum = res.result.totalCount;
      }
    },
    delFunc(row) {
      this.$confirm(`确定要删除么？`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          let res = await this.$http.appConfigDel({id:row.id})
          if (res) {
            this.$message.success("操作成功！");
            this.getTableList();
          }
        })
        .catch(() => {});
    },
    unmountFunc(row) {
      this.$confirm(`确定要${row.status?'下架':'上架'}么？`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          let res = await this.$http.appConfigSave({ ...row , status:row.status==0?1:0 })
          if (res) {
            this.$message.success("操作成功！");
            this.getTableList();
          }
        })
        .catch(() => {});
    },
  },
};
</script>

<style scoped>
.el-form .el-input{
  width: 400px !important;
}
</style>
